import React, { useEffect } from 'react'


export default function Header(props) {

  let linkto;

  // const pageNowParent = document.querySelectorAll("#top");
  // const pageNowHome = document.getElementsByClassName("home");
  // console.log("pageNowHome : " + pageNowHome.length);


  const LeavePage = () => {
    // alert("stop");
    // delayClick(alink[i].rel);
    closeNav();

    //home element
    var divGroup = document.getElementsByClassName("in")[0];
    var line1 = document.getElementsByClassName("line1")[0];
    var title1 = document.getElementsByClassName("title1")[0];
    var title2 = document.getElementsByClassName("title2")[0];
    var title3 = document.getElementsByClassName("title3")[0];
    var title4 = document.getElementsByClassName("title4")[0];
    const galleryUL = document.querySelectorAll("ul.gallerywrap");
    for (let i = 0; i < galleryUL.length; i++) {
      galleryUL[i].classList.remove("galleryIn");
      galleryUL[i].classList.add("galleryOut");
    }

    divGroup.classList.remove("in");
    divGroup.classList.add("out");

    line1.classList.add("hide");
    line1.classList.remove("show");

    title1.classList.add("hide");
    title1.classList.remove("show");

    title2.classList.add("hide");
    title2.classList.remove("show");

    title3.classList.add("hide");
    title3.classList.remove("show");

    title4.classList.add("hide");
    title4.classList.remove("show");

  };

  const openNav = () => {
    console.log("nav click");
    document.getElementById("navmenu").classList.remove("hide");
    document.getElementById("navmenu").classList.add("in");
    document.getElementById("navmenu").classList.remove("out");
    document.body.classList.add("hidden");
  }

  const closeNav = () => {
    console.log("nav close");
    document.getElementById("navmenu").classList.add("out");
    document.getElementById("navmenu").classList.remove("in");
    setTimeout(function () {
      document.getElementById("navmenu").classList.add("hide");
    }, 500);
    document.body.classList.remove("hidden");
  }




  // const delayClick = (msg) => {
  //   linkto = msg

  //   var line1 = document.getElementsByClassName("line1")[0];
  //   const galleryUL = document.querySelectorAll("ul.gallerywrap");
  //   for (let i = 0; i < galleryUL.length; i++) {
  //     galleryUL[i].classList.remove("galleryIn");
  //     galleryUL[i].classList.add("galleryOut");
  //   }

  //   line1.classList.add("hide");
  //   line1.classList.remove("show");

  //   setTimeout(GoLink, 1000);
  // }
  function GoLink() {
    window.location.href = linkto;
  }





  useEffect(() => {

    const alink = document.querySelectorAll(".navGroup a.line2");
    const alink2 = document.querySelectorAll("a.home");

    for (let i = 0; i < alink.length; i++) {
      alink[i].addEventListener("click", function () {
        LeavePage();
      });
    }

    for (let i = 0; i < alink2.length; i++) {
      alink2[i].addEventListener("click", function () {
        LeavePage();
      });
    }

    // window.onhashchange = function(){alert("back")};


    document.getElementById("navopen").addEventListener("click", function () {
      openNav();
    });
    document.getElementById("navclose").addEventListener("click", function () {
      closeNav();
    });
    return () => {
    };
  }, []);


  return (
    <div>
      <div id="navmenu" className="hide">
        <header className="navMenuHeader">
          <a href={props.href} className={"home dlink"} title="alexson chu">/alexson</a>
          <a id="navclose" className="menu" title="alexson">✖</a>
        </header>
        <nav className="navGroup">
          <span className="line1nav"><span className="cp">_</span></span>
          <a title="" href="/" className="line2"><span className="cp">home</span></a>
          <a title="" href="/projects" className="line2"><span className="cp">projects</span></a>
          <a title="" href="/about" className="line2"><span className="cp">about</span></a>
          <a title="" href="/contact" className="line2"><span className="cp">contact</span></a>
        </nav>
        <span className="icodown"></span>
      </div>

      <header role="banner" id="top" className={props.className}>
        <div className="wrap clearfix">
          <a href={props.href} className={"home dlink"} title="alexson chu">/alexson</a>
          <a id="navopen" className="menu" title="menu">&#10133;</a>
        </div>
      </header>
    </div>
  )
}
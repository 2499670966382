import React, { useEffect } from "react";
import ReactGA from 'react-ga'
import { Helmet } from "react-helmet"

export default function Application(props) {

    useEffect(() => {
        ReactGA.initialize('UA-31658450-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    })

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>alexson chu. {props.pageNow}</title>
                {/* <meta name="robots" content="noimageindex, nofollow, nosnippet"/ > */}
                <meta name="robots" content="index, follow" />
                <meta name="description" content={props.pageNow + " | alexson chu. Multimedia Creator from Hong Kong 🇭🇰"} />
                <meta name="author" content="Alexson Chu from Hong Kong" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://rightunex.com/" />
            </Helmet>
        </div>
    )
}